import React from "react";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import Small_polygon from "../../../assets/imgs/small_polygon.svg";
import ArrowIcon from "../../../assets/imgs/arrow.svg";
import Null_player from "../../../assets/imgs/null-player-img.svg";
import { teamColors } from "../../../config/constants/teamColors";

type FeaturePlayersCardProps = {
  playerSmallIcon: string;
  playerName: string;
  playerPosition: string;
  playerStatus: string;
  bgColor: string
};

const FeaturePlayersCard: React.FC<FeaturePlayersCardProps> = ({
  playerSmallIcon,
  playerName,
  playerPosition,
  playerStatus,
  bgColor
}) => {
  const formatPlayerStatus = (status: string) => {
    //if status is undefined or null return empty string
    if (status === undefined) return "";
    if (status === null) return "";
    return status.replace(/!/g, '').trim();
  };

  return (
    <div
      className="p-3 pb-5 flex gap-4 items-center rounded-2xl relative cursor-pointer"
      style={{
        background: Colors.backgroundSecundary,
      }}
    >
      <div className="relative w-[54px] h-[47.84px] xl:w-[67px] xl:h-[60.84px] md:w-[60px] md:h-[50.84px] ">
        <div className="rounded-xl w-[53px] md:w-[58px] xl:w-[64px] 2xl:w-[71px] h-[53px] md:h-[58px] xl:h-[64px] 2xl:h-[71px] flex items-end overflow-hidden" style={{
          backgroundColor: bgColor ? teamColors[bgColor] : "#4F2683"
        }}>
          <img
            className={ playerSmallIcon ? " scale-[1.7]": "h-[45px]"}
            src={playerSmallIcon || Null_player}
            alt=""
          />
        </div>
        <div className="absolute -bottom-1.5 md:-bottom-2 -right-[10px] md:-right-[15px]">
          <div className="relative">
            <img src={Small_polygon} alt="" className="w-[22px] md:w-[24.7px] xl:w-[26.7px] 2xl:w-[29.7px]" />
            <Paragraph
              text={playerPosition}
              props={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              className="text-[8px] md:text-[9px] 2xl:text-[10.8px] font-semibold"
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
        </div>
      </div>
      <div className="w-[80%]">
        <Paragraph
          text={playerName}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-[18px] md:text-[20.527px] xl:text-[22.527px] 2xl:text-[29.527px] leading-[110%] font-medium"
        />
        <Paragraph
          text={formatPlayerStatus(playerStatus)}
          props={{
            fontSize: "10px",
            marginTop: "6px",
            marginLeft: "3px",
            opacity: 0.7
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-[18px] xl:text-[12px] 2xl:text-[13.5px]"
        />
      </div>
      <img
        src={ArrowIcon}
        alt=""
        className="self-start -mr-5 absolute top-3 right-8"
      />
    </div>
  );
};

export default FeaturePlayersCard;
