import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Paragraph } from "../Commons/Paragraph";
import { Button } from "../ui/button";
import pop_up_bg from "../../assets/imgs/capabilities-modal.svg";
import tick from "../../assets/imgs/tick.svg";

interface CapabilitiesModalProps {
  isVisible: boolean;
  onClose: () => void;
  screenData: any;
  postFix: string;
}

const CapabilitiesModal: React.FC<CapabilitiesModalProps> = ({
  isVisible,
  onClose,
  screenData,
  postFix,
}) => {
  const [currentCapabilities, setCurrentCapabilities] = useState<any>([]);

  useEffect(() => {
    if (screenData) {
      const features = JSON.parse(screenData[`currentCapabilities${postFix}`]);
      setCurrentCapabilities(features);
    }
  }, [screenData, postFix]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-30 flex justify-center"
          onClick={onClose}
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
            WebkitBackdropFilter:"blur(9px)"
          }}
        >
          <motion.div
            className="text-white flex justify-center items-end sm:items-center h-full lg:p-[14px] max-w-[500px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div
             className="rounded-[20px] rounded-b-none sm:rounded-b-[20px]"
              style={{
                background: "#212130",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <img
                src={pop_up_bg}
                alt="CapabilitiesModal Background"
                className="rounded-t-[20px] w-full max-h-[300px]"
              />
              <div className="mt-5 text-center p-2">
                <Paragraph
                  text="RotoBot Capabilities that "
                  props={{
                    fontSize: "23px",
                    fontWeight: "600",
                    fontStyle: "Italic",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                  className="mb-1.5"
                />
                <Paragraph
                  text="Are Available Today"
                  props={{
                    fontSize: "23px",
                    fontWeight: "600",
                    fontStyle: "Italic",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                />
                <div className="px-3 py-5 mt-4 rounded-xl flex flex-wrap gap-4">
                  {currentCapabilities.map((feature: any, index: any) => (
                    <div className="flex gap-3" key={index}>
                      <Button
                        className={`gap-[4px] text-[11.76px] font-medium w-auto ${feature.comingSoon ? "p-[7.83px_15.66px] rounded-[8.28px] bg-[rgba(255,255,255,0.1)] text-[rgba(255,255,255,0.75)]" : "p-[6.54px_8px] rounded-[6.92px]"} `}
                      >
                        {feature.name}
                        {!feature.comingSoon && (
                          <img src={tick} alt="" className="w-[18px]" />
                        )}
                      </Button>
                    </div>
                  ))}
                </div>
                <Button className="text-[13.5px] font-medium mt-6 mb-5 w-[80%]">
                  Get Started
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CapabilitiesModal;
