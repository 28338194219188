import { Button } from "../ui/button";
import PhoneNumberInput from "../Commons/PhoneNumberInput/PhoneNumberInput";
import { Colors } from "../Commons/Colors/Colors";
import { VerifyPhoneNumberWidgetProps } from "./type";
import { useState } from "react";

const VerifyPhoneNumberWidget: React.FC<VerifyPhoneNumberWidgetProps> = ({
  phoneNumber,
  handlePhoneNumberChange,
  isValid,
  handleSendSMS,
}) => {
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const handleOnChange = (e: string) => {
    if (e) {
      handlePhoneNumberChange(e);
    } else {
      handlePhoneNumberChange("");
    }
  };

  return (
    <div className="mt-8 w-full flex text-center justify-center h-full">
      <div className="sm:w-1/2 sm:px-10 w-full max-h-full min-h-full flex flex-col ">
      <div >
        <div className="sm:text-6xl font-medium text-white text-center text-3xl">
          What&rsquo;s your mobile phone number?
        </div>
        <div className="mt-10 ml-[40px]">
          <PhoneNumberInput value={phoneNumber} onChange={handleOnChange} />
          {!isValid && (
            <p className="text-red-500 mt-4">Please enter a valid phone number.</p>
          )}
        </div>
        <div
          className=" w-10/12 mt-6 text-center sm:text-xl ml-5"
          style={{
            color: Colors.textLigth,
          }}
        >
          Standard messaging and data rates may apply. Please check with your mobile provider for details on your plan&rsquo;s text messaging charges.
        </div>
      </div>
      <div className="w-full mt-8">
        <Button onClick={handleSendSMS} disabled={!isPhoneValid} className="text-xl">Verify via Text Message</Button>
      </div>
      </div>
    </div>
  );
};

export default VerifyPhoneNumberWidget;
