import React, { useEffect, useState, useRef } from "react";
import { Button } from "../ui/button";
import RatingStar from "../Commons/Rating/Rating";
import { Paragraph } from "../Commons/Paragraph";
import { Textarea } from "../ui/textarea";
import { Colors } from "../Commons/Colors/Colors";
import { createFeedBack } from "../../Controllers/Feedback";
import { AnimatePresence, motion } from "framer-motion";
import cancelIcon from "../../assets/imgs/cancel-icon.png";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import successAnimation from "../../assets/animations/success-animation.json";
import LottieAnimation from "../Commons/LottieAnimation/LottieAnimation";

interface Props {
  setOpen: (isOpen: boolean) => void;
  open: boolean;
}

const FeedbackModal = ({ setOpen, open }: Props) => {
  const [ratings, setRatings] = useState({
    accuracy: 0,
    responseTime: 0,
    easeOfUse: 0,
    helpfulness: 0,
  });
  const [message, setMessage] = useState("");
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const { getUserData } = useSupabaseAuth();

  const fetchCurrentUser = async () => {
    const response = await getUserData();
    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleCreateFeedBack = async () => {
    const response = await createFeedBack(
      currentUser?.id,
      ratings.accuracy,
      ratings.responseTime,
      ratings.easeOfUse,
      ratings.helpfulness,
      message,
    );

    if (response.status) {
      setIsSubmitted(true);
      setMessage("");
      setRatings({
        accuracy: 0,
        responseTime: 0,
        easeOfUse: 0,
        helpfulness: 0,
      });

    } else {
      console.log("something wrog with the Feedback, contact support please!!");
    }
  };

  const handleRatingChange = (key: string, value: number) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [key]: value,
    }));
  };

  const handleFocus = () => {
    setIsKeyboardOpen(true);
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 300);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage("");
    setRatings({
      accuracy: 0,
      responseTime: 0,
      easeOfUse: 0,
      helpfulness: 0,
    });
  };

  const handleDone = () => {
    handleClose();
    setIsSubmitted(false);
  };


  const handleBlur = () => {
    setIsKeyboardOpen(false);
  };

  return (

    <AnimatePresence>
      {open && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-30"

          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
            WebkitBackdropFilter: "blur(9px)",
          }}
          onClick={handleClose}
        >
          <motion.div
            className="text-white flex justify-center items-end h-full p-[14px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            onClick={(e) => e.stopPropagation()}

          >
            <div
              className={`fixed left-[50%]  z-50 grid max-w-lg translate-x-[-50%] gap-4 bg-[#181A2078] border border-[#ffffff1a] backdrop-blur-[75px] p-6 shadow-lg rounded-[20px] w-11/12 transition-all duration-500 ${
                isKeyboardOpen ? "max-h-[470px] overflow-auto bottom-[10%]" : "h-auto top-[50%] -translate-y-[50%]"
              }`}
            >
              {!isSubmitted ? (
                <>
                  <div className={`transition-all duration-500 relative`}>
                    <Paragraph
                      text={"Feedback"}
                      props={{ fontSize: "20px", fontWeight: "700" }}
                      ParagraphType="Pragragh22"
                      color="textPrimary"
                      className="text-xl text-center"
                    />
                    <div
                      className="cursor-pointer items-center right-2 top-2 absolute"
                      onClick={handleClose}
                    >
                      <img src={cancelIcon} alt="Cancel" />
                    </div>

                    <div
                      className={`flex items-center space-x-2 mt-4 ${
                        isKeyboardOpen
                          ? "absolute -top-1/2 left-0 w-full opacity-0"
                          : ""
                      }`}
                    >
                      <div className="flex flex-col gap-8 w-full mt-3">
                        <RatingStar
                          label="Accuracy"
                          value={ratings.accuracy}
                          onChange={(value) =>
                            handleRatingChange("accuracy", value)
                          }
                        />
                        <RatingStar
                          label="Response Time"
                          value={ratings.responseTime}
                          onChange={(value) =>
                            handleRatingChange("responseTime", value)
                          }
                        />
                        <RatingStar
                          label="Ease of Use"
                          value={ratings.easeOfUse}
                          onChange={(value) =>
                            handleRatingChange("easeOfUse", value)
                          }
                        />
                        <RatingStar
                          label="Helpfulness"
                          value={ratings.helpfulness}
                          onChange={(value) =>
                            handleRatingChange("helpfulness", value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-5">
                    <Paragraph
                      text={"Any additional feedback"}
                      props={{ fontSize: "15px", fontWeight: "500" }}
                      ParagraphType="Pragragh22"
                      color="textPrimary"
                      className={`transition-transform duration-500`}
                    />

                    <Textarea
                      ref={textareaRef}
                      className={`bg-[${Colors.backgroundSecundary}] border-0 focus:ring-0 mt-2 text-white text-base placeholder:text-[${Colors.backgroundSecundary}] transition-transform duration-500 ${
                        isKeyboardOpen ? "h-[20vh]" : " h-[169px]"
                      }`}
                      placeholder="Start typing here..."
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(event) => setMessage(event.target.value)}
                      value={message}
                    />

                    <Button className="mt-6 text-[15px]" onClick={handleCreateFeedBack}>
                      Submit
                    </Button>
                  </div>
                </>
              ) : (
              <div className="flex flex-col items-center justify-center">
                <Paragraph
                  text={"Submitted"}
                  props={{ fontSize: "36px", fontWeight: "700" }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                  className="text-xl text-center"
                />
                <LottieAnimation animationData={successAnimation} />
                <Paragraph
                  text={"Thank you for your feedback!"}
                  props={{ fontSize: "15px", fontWeight: "500" }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                  className="text-center mt-4"
                />
                <Button className="mt-16 text-[15px]" onClick={handleDone}>
                  Done
                </Button>
              </div>
             )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>

  );
};

export default FeedbackModal;
