import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "../ui/button";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import CancelIcon from "../../assets/imgs/cancel-white-icon.svg";

interface DeleteAccountModalProps {
  isVisible: boolean;
  onClose: () => void;
  handleDel: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  isVisible,
  onClose,
  handleDel,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-30 flex justify-center"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(11px)",
          }}
        >
          <motion.div
            className="text-white flex items-center h-full p-4 max-w-[500px] w-full"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div className="w-full min-h-[191.803px] rounded-[20px] bg-[#252537]">
              <div
                className="flex justify-between rounded-t-3xl py-3 px-4"
                style={{ backgroundColor: Colors.backgroundSecundary }}
              >
                <Paragraph
                  text="Delete Your Account?"
                  className="text-[17.705px] !font-semibold mt-1"
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <div className="cursor-pointer" onClick={onClose}>
                  <img src={CancelIcon} alt="CancelIcon" className="w-6" />
                </div>
              </div>
              <div className="py-6 px-3">
                <Paragraph
                  text="This action cannot be undone"
                  className="text-[13.77px] opacity-50"
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <div
                  className="h-[1px] w-[95%] mx-auto mt-3"
                  style={{ backgroundColor: Colors.backgroundSecundary }}
                />
                <div className="flex justify-end gap-2 mt-6">
                  <Button
                    onClick={onClose}
                    className="bg-transparent hover:bg-transparent border-[1px] border-[#FFFFFF1A] text-[9px] !font-semibold h-7 rounded-[5px] max-w-[129.836px]"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDel}
                    className="bg-[#F11E44] hover:bg-[#F11E44] text-[9px] !font-semibold h-[30px] rounded-[5px] max-w-[129.836px]"
                  >
                    Delete Account
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DeleteAccountModal;
