import React from "react";
import "./App.css";
import { AuthContextProvider } from "./Context/AuthContext/AuthContext";
import { FirebaseContextProvider } from "./Context/FirebaseContext/FirebaseContext";
import { BrowserRouter } from "react-router-dom";

// import Login from "./Components/Loging";
// import { Input } from './Components/Commons';

//cache request
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AppRoutes } from "./Routes/routes";
import { SafeAreaProvider } from "./Context/SafeAreaContext/SafeAreaContext";
import AppUrlListener from "./Services/AppUrlListener";
import FirebaseAnalyticsService from "../src/Services/FirebaseAnalyticsService";
import { ApplePayContextProvider } from "./Context/AppleContext/AppleContext";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <FirebaseContextProvider>
        <ApplePayContextProvider>
          <AuthContextProvider>
            <BrowserRouter>
              <SafeAreaProvider>
                <AppRoutes />
                <AppUrlListener />
                <FirebaseAnalyticsService />
              </SafeAreaProvider>
            </BrowserRouter>
          </AuthContextProvider>
        </ApplePayContextProvider>
      </FirebaseContextProvider>
    </QueryClientProvider>
  );
};

export default App;
