import React, { useState, useEffect, useRef } from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { AnimationProps } from "./type";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

const TypingAnimation: React.FC<AnimationProps> = ({
  isActivateAnimation,
  onInactivateAnimation,
  text,
  index,
  id,
  setId,
  setIsActivateAnimation,
}) => {
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [displayResponse, setDisplayResponse] = useState("");
  const [hapticFeedbackTriggered, setHapticFeedbackTriggered] = useState(false);

  const scrollToBottom = () => {
    const lastMessage = lastMessageRef.current;
    if (lastMessage) {
      lastMessage.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [text, isActivateAnimation]);

  const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
  };

  useEffect(() => {
    if (isActivateAnimation) {
      setId(id);
      let i = 0;
      const stringResponse = text;

      const intervalId = setInterval(() => {
        setDisplayResponse(stringResponse.slice(0, i));

        if (stringResponse[i - 1] === " " || i === stringResponse.length) {
          setHapticFeedbackTriggered(true);
          setTimeout(() => {
            setHapticFeedbackTriggered(false);
          }, 100);
          hapticsImpactLight();
        }

        i++;

        if (i > stringResponse.length) {
          clearInterval(intervalId);
          setId(null);
          setIsActivateAnimation(false);
          scrollToBottom();
        }
      }, 10);
      onInactivateAnimation();
      return () => clearInterval(intervalId);
    } else {
      setDisplayResponse(text);
    }
  }, []);

  useEffect(() => {
    if (isActivateAnimation) {
      const container = containerRef.current;
      if (!container) return;

      const resizeObserver = new ResizeObserver(() => {
        scrollToBottom();
      });

      resizeObserver.observe(container);
      return () => {
        if (container) {
          resizeObserver.unobserve(container);
        }
      };
    }
  }, []);

  return (
    <div key={`typing-${index}`} ref={containerRef}>
      {/* Split displayResponse into paragraphs */}
      {displayResponse.split("\n\n").map((paragraph, paragraphIndex) => (
        <div
          className={`${
            paragraph === "Error. Please try again."
              ? "text-[#F11E44] font-semibold"
              : "text-[#FFFFFFB2]"
          }`}
          key={`typing-paragraph-${paragraphIndex}`}
        >
          <Paragraph
            text={paragraph}
            props={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "155%",
              color: "",
            }}
            ParagraphType="PragraghSemiBold600"
            color="textLigth"
          />
        </div>
      ))}
      <div ref={lastMessageRef} />
    </div>
  );
};

export default TypingAnimation;
