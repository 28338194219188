import React, { useState } from "react";
import { Layout } from "../Layouts/Layout";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import { useNavigate } from "react-router-dom";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { motion, AnimatePresence } from "framer-motion";

// Icons
import CharPersonIcon from "../../assets/imgs/player-analyzer.svg";
import StatResearch from "../../assets/imgs/stat-research-icon.svg";
import PeopleComunityIcon from "../../assets/imgs/task-list-square-person.svg";
import PersonQuestionIcon from "../../assets/imgs/waiver-wire-icon.svg";
import TradeAnalyzer from "../../assets/imgs/trade-analyzer.svg";
import LatestNews from "../../assets/imgs/latest-news-icon.svg";
import { useSafeAreaInsets } from "../../Context/SafeAreaContext/SafeAreaContext";

interface CapabilitiesProps {
  isVisible?: boolean;
  onClose?: () => void;
}

const capabilities = [
  {
    icon: PeopleComunityIcon,
    title: "Sit/Start Decider",
    description: (
      <>
        The <span className="font-bold"> Sit/Start Decider</span> helps you
        decide who to start or sit based on player performance and matchup
        analysis.
      </>
    ),
    question: [
      <>
        Should I start<b><i> Player A</i></b> or{" "}
        <b>
          {" "}
          <b><i> Player B</i></b>
        </b>
        ?
      </>,
      <>
        <b><i> Player A</i></b> or <b><i> Player B</i></b> this week?
      </>,
      <>
        <b><i> Player A</i></b>, <b><i> Player B</i></b>, <b><i> Player C</i></b>, start two.
      </>,
      <>
        Is it worth starting<b><i> Player A</i></b> this week over <b><i> Player B</i></b>?
      </>,
      <>
        Should I bench<b><i> Player A</i></b>, <b><i> Player B</i></b> or <b><i> Player C</i></b>{" "}
        this week?
      </>,
    ],
  },
  {
    icon: PersonQuestionIcon,
    title: "Waiver Assistant",
    description: (
      <>
        The <span className="font-bold">Waiver Assistant</span> helps you choose
        the best players to pick up from your waiver wire.
      </>
    ),
    question: [
      <>
        Should I drop<b><i> Player A</i></b> for <b><i> Player B</i></b>?
      </>,
      <>
        How much FAAB should I spend for<b><i> Player A</i></b>?
      </>,
      <>Any good RB’s worth grabbing on waivers this week?</>,
      <>Who are the top waiver targets for this week?</>,
      <>Who are the best FLEX players on the waiver wire?</>,
    ],
  },
  {
    icon: LatestNews,
    title: "Latest News",
    description: (
      <>
        <span className="font-bold">Latest News</span> keeps you updated with
        the most relevant news for your fantasy team.
      </>
    ),
    question: [
      <>
        What’s the latest on<b><i> Player A</i></b>?
      </>,
      <>
        Do you have an update on<b><i> Player A</i></b>’s injury?
      </>,
      <>
        Is<b><i> Player A</i></b> going to play this week?
      </>,
      <>
        Give me your most recent update on<b><i> Player A</i></b>.
      </>,
      <>
        Is<b><i> Player A</i></b> emerging as the WR1 on his team?
      </>,
    ],
  },
  {
    icon: CharPersonIcon,
    title: "Player Analyzer",
    description: (
      <>
        The{" "}
        <span className="font-bold">
          <b><i> Player A</i></b>nalyzer
        </span>{" "}
        provides a detailed breakdown of a player’s stats, trends, and
        projections.
      </>
    ),
    question: [
      <>
        Tell me about<b><i> Player A</i></b>’s matchup this week.
      </>,
      <>
        How will<b><i> Player A</i></b> perform for the rest of the season?
      </>,
      <>
        Is<b><i> Player A</i></b> a buy low candidate right now?
      </>,
      <>
        Give an analysis of<b><i> Player A</i></b>’s season so far.
      </>,
    ],
  },
  {
    icon: TradeAnalyzer,
    title: "Trade Analyzer",
    description: (
      <>
        The <span className="font-bold">Trade Analyzer</span> offers insights on
        proposed trades to determine which side gains the most value.
      </>
    ),
    question: [
      <>
        Should I trade<b><i> Player A</i></b> for <b><i> Player B</i></b>?
      </>,
      <>Who are some good buy low candidates to trade for?</>,
      <>Any players that I should be selling high?</>,
      <>
        Should I trade <b><i> Player B</i></b> and C for<b><i> Player A</i></b>?
      </>,
      <>
        What’s the downside of trading<b><i> Player A</i></b> for <b><i> Player B</i></b>?
      </>,
      <>
        I want to trade<b><i> Player A</i></b> away, what can I get for him?
      </>,
      <>
        I want to target<b><i> Player A</i></b>, who would I need to give away?
      </>,
    ],
  },
  {
    icon: StatResearch,
    title: "Stat Research",
    description: (
      <>
        <span className="font-bold">Stat Research</span> allows you to explore
        stats and insights on players, teams, or matchups.
      </>
    ),
    question: [
      <>Who are the leaders in YPRR this season?</>,
      <>Which RBs have the most attempts this season?</>,
      <>Which QBs face the highest pressure rates?</>,
      <>Which RB’s have the most TDs per snap this season?</>,
      <>Which player currently leads the league in drops?</>,
    ],
  },
];

const CapabilityCard = ({ capability, isSelected, onClick }: any) => (
  <div
    className={`rounded-2xl md:rounded-3xl lg:rounded-[33.413px] px-2 py-3 max-w-[171px] md:max-w-[232.037px] lg:max-w-[332.037px] flex items-center justify-center gap-2 border-[1px] md:border-[1.898px]  cursor-pointer`}
    onClick={onClick}
    style={{
      backgroundColor: isSelected ? Colors.backgroundSecundary : "",
      borderColor: !isSelected ? "#FFFFFF33" : "transparent",
    }}
  >
    <Paragraph
      text={capability.title}
      className="text-[13px] md:text-xl lg:text-[25.059px]"
      ParagraphType="Pragragh22"
      color="textPrimary"
    />
    <img src={capability.icon} alt={capability.title} className="w-6 md:w-8 lg:w-[48.396px]" />
  </div>
);

const QuestionsList = ({ questions }: any) => (
  <>
    <Paragraph
      text="What type of questions can I ask?"
      props={{ fontWeight: 600 }}
      ParagraphType="Pragragh22"
      color="textPrimary"
      className="text-base md:text-2xl lg:text-[32px] mt-6 md:mt-8 lg:mt-10"
    />
    <div className="lg:mt-10">
      {questions.map((ques: React.ReactNode, index: number) => (
        <div className="flex gap-3 mt-6" key={index}>
          <div
            className="w-3 h-3 lg:w-[22.5px] md:w-4 lg:h-[22.5px] md:h-4 rounded-full mt-[4.3px]"
            style={{ backgroundColor: Colors.bgGreenPrimary }}
          ></div>
          <div className="text-xs md:text-xl lg:text-2xl text-white">{ques}</div>
        </div>
      ))}
    </div>
  </>
);

const Capabilities = ({ isVisible, onClose }: CapabilitiesProps) => {
  const { top, bottom, left, right } = useSafeAreaInsets();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);

  const navigateBack = () => {
    navigate("/chat/new");
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full z-30 h-screen overflow-y-auto capabilities-bg"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            paddingTop: top,
            paddingBottom: bottom,
            paddingLeft: left,
            paddingRight: right,
          }}
        >
          <div className="p-5 lg:pl-20">
            <div className="flex items-center justify-between">
              <div
                className="cursor-pointer rounded-[100px] w-[58px] h-[58px] lg:w-[84.1px] md:w-[65px] md:h-[65px] lg:h-[84.1px] flex justify-center items-center"
                style={{ background: Colors.backgroundSecundary }}
                onClick={onClose}
              >
                <img src={ArrowBackIcon} alt="Back" className="lg:w-5 md:w-3" />
              </div>
              <Paragraph
                text="Capabilities"
                props={{
                  fontWeight: 600,
                }}
                className="text-[28px] md:text-4xl lg:text-[40px] -ml-[45px] lg:-ml-24"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
              <div></div>
            </div>

            <div className="lg:w-[80%]">

              <div className="mt-6 lg:mt-16">
                <div className="hidden sm:block text-xs md:text-lg lg:text-2xl text-white">
                  Explore RotoBot AI’s day-to-day capabilities. Click each category to see what you can ask and how it can help.
                </div>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-3 mt-10 sm: gap-x-4 gap-y-6 md:gap-y-9">
                {capabilities.map((capability, index) => (
                  <CapabilityCard
                    key={index}
                    capability={capability}
                    isSelected={selected === index}
                    onClick={() => setSelected(index)}
                  />
                ))}
              </div>

              <div className="mt-6 lg:mt-16">
                <div className="text-xs md:text-lg lg:text-2xl text-white">
                  {capabilities[selected].description}
                </div>
                <QuestionsList questions={capabilities[selected].question} />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Capabilities;
