import { useEffect, useState } from "react";
import { Layout } from "../Layouts/Layout";
import { useNavigate } from "react-router-dom";
import { getROSRankings } from "../../Controllers/PlayerRanking";
import { positions } from "../Rankings/data";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";

import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import TablePlayers from "./TablePlayers/TablePlayers";
import TableTabs from "../Commons/TableTabs/TableTabs";
import { Button } from "../ui/button";
import WaveProgressBar from "./WaveProgressBar/WaveProgressBar";
import TradeReport from "./TradeReport/TradeReport";
import { getTradeAnalyzer } from "../../Controllers/TradeAnalyzer/TradeAnalyzer";
import { DataItem } from "../../Controllers/TradeAnalyzer/type";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import useFirebaseAnalytics from "../../hooks/useFirabaseAnality";
import { motion, AnimatePresence } from "framer-motion";


type CategoryItem = {
  id: number;
  name: string;
};

type PositionItem = {
  id: number;
  name: string;
};

const TradeAnalyzer = () => {
  const navigate = useNavigate();
  const { logEventAnalytics } = useFirebaseAnalytics();

  const [playersData, setPlayersData] = useState<DataItem[]>([]);
  const [tradeScreen, setTradeScreen] = useState<string>("TradeAway");
  const [disabledAdd, setDisabledAdd] = useState<boolean>(false);
  const [playersDataTradeAway, setPlayersDataTradeAway] = useState<DataItem[]>(
    [],
  );
  const [playersDataTradeFor, setPlayersDataTradeFor] = useState<DataItem[]>([]);

  const [positionSelected, setPosicionSelected] = useState<PositionItem>({
    id: 1,
    name: "All",
  });
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>();
  const [report, setReport] = useState<string | null | undefined>("");
  const [tradeOutcome, setTradeOutcome] = useState<string | null | undefined>("");
  const { getUserData } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchaPlayerData = async () => {
    const rosRankings = await getROSRankings();
    if (!rosRankings?.data) return;
    let filteredPlayers = rosRankings?.data;

    filteredPlayers = filteredPlayers
      .filter(
        (player: DataItem) =>
          !playersDataTradeAway.some(
            (tradedPlayer) =>
              tradedPlayer.roto_player_id === player.roto_player_id,
          ),
      )
      .filter(
        (player: DataItem) =>
          !playersDataTradeFor.some(
            (tradedPlayer) =>
              tradedPlayer.roto_player_id === player.roto_player_id,
          ),
      );

    if (searchTerm) {
      filteredPlayers = filteredPlayers.filter(
        (player: any) =>
          player.playerName.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (positionSelected.name === "All" ||
            player.playerPosition === positionSelected.name),
      );
    } else if (positionSelected.name !== "All") {
      filteredPlayers = filteredPlayers.filter(
        (player: any) => player.playerPosition === positionSelected.name,
      );
    }

    setPlayersData(filteredPlayers);
  };

  useEffect(() => {
    fetchaPlayerData();
  }, [positionSelected, searchTerm, playersDataTradeAway, playersDataTradeFor]);

  useEffect(() => {
    if (tradeScreen === "TradeAway") {
      setDisabledAdd(playersDataTradeAway.length >= 3);
    } else {
      setDisabledAdd(playersDataTradeFor.length >= 3);
    }
  }, [tradeScreen, playersDataTradeAway, playersDataTradeFor]);

  const handleSelectPosition = (item: PositionItem) => {
    setPosicionSelected(item);
  };

  const handleFilterSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleSelectPlayer = (newPlayer: DataItem) => {
    if (tradeScreen === "TradeAway") {
      logEventAnalytics("trade_player_selected_away", {
        name: "Player Selected Trade Away",
        playerId: newPlayer.roto_player_id
      });
      setPlayersDataTradeAway((prevPlayers) => [...prevPlayers, newPlayer]);
    } else {
      logEventAnalytics("trade_player_selected_for", {
        name: "Player Selected Trade For",
        playerId: newPlayer.roto_player_id
      });
      setPlayersDataTradeFor((prevPlayers) => [...prevPlayers, newPlayer]);
    }
  };

  const removePlayerTradeAway = (idToRemove: string | undefined) => {
    setPlayersDataTradeAway((prevPlayers) =>
      prevPlayers.filter((player) => player.roto_player_id !== idToRemove),
    );
  };

  const removePlayerTradeFor = (idToRemove: string | undefined) => {
    setPlayersDataTradeFor((prevPlayers) =>
      prevPlayers.filter((player) => player.roto_player_id !== idToRemove),
    );
  };

  const handleButtonNext = () => {
    logEventAnalytics("trade_nav_next", {
      name: "Trade Analyzer Next",
    });
    setTradeScreen("TradeFor");
  };

  const handleButtonBack = () => {
    if (tradeScreen === "TradeAway") {
      navigate("/home");
    } else {
      setTradeScreen("TradeAway");
    }
  };

  const handleButtonCalculate = async () => {
    logEventAnalytics("trade_calculate", {
      name: "Trade Analyzer Trade Analyzed",
    });
    //calculate
    const userId = currentUser?.id;
    setTradeScreen("Calculating");
    const tradeData = await getTradeAnalyzer(playersDataTradeAway, playersDataTradeFor, userId);
    setTradeScreen("Report");
    setReport(tradeData.data?.detail_chat?.ai_response);
    setTradeOutcome(tradeData.data?.trade_outcome);
  };

  const handleOnReset = () => {
    logEventAnalytics("trade_complete_again", {
      name: " Trade Analyzer Complete New Query",
    });
    setPlayersDataTradeAway([]);
    setPlayersDataTradeFor([]);
    setSearchTerm("");
    setTradeScreen("TradeAway");

  }

  return (
    <>
      <Layout>
        {(tradeScreen === "TradeAway" || tradeScreen === "TradeFor") && (
          <div className="flex h-full relative -m-5">
            <div className="w-full">
              <div className="flex-1 pb-5">
                <div className="p-5">
                  <div className="flex items-center justify-between">
                    <div
                      className="cursor-pointer rounded-full w-14 h-14 flex justify-center items-center"
                      style={{
                        background: Colors.backgroundSecundary,
                      }}
                      onClick={() => handleButtonBack()}
                    >
                      <img src={ArrowBackIcon} alt="" />
                    </div>
                    <Paragraph
                      text={
                        tradeScreen === "TradeAway"
                          ? "Trading Away"
                          : "Trading For"
                      }
                      props={{ fontSize: "22px", marginLeft: "-45px" }}
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                    />
                    <div></div>
                  </div>
                  <div className="flex justify-center">
                    <Paragraph
                      text={
                        tradeScreen === "TradeAway"
                          ? "Choose the players to trade away."
                          : "Choose the players to trade for."
                      }
                      props={{ fontSize: "14px", opacity: "0.75" }}
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                    />
                  </div>
                </div>

                {tradeScreen === "TradeAway" &&
                  playersDataTradeAway.length > 0 && (
                    <TablePlayers
                      tableHeader={["Selected Players"]}
                      data={playersDataTradeAway}
                      selected={true}
                      disabled={false}
                      onSelectPlayer={(player: DataItem) => {
                        removePlayerTradeAway(player?.roto_player_id);
                      }}
                    />
                  )}

                {tradeScreen === "TradeFor" &&
                  playersDataTradeFor.length > 0 && (
                    <TablePlayers
                      tableHeader={["Selected Players"]}
                      data={playersDataTradeFor}
                      selected={true}
                      disabled={false}
                      onSelectPlayer={(player: DataItem) => {
                        removePlayerTradeFor(player?.roto_player_id);
                      }}
                    />
                  )}

                <div className="p-5 flex flex-col">
                  <div className="flex flex-col justify-center">
                    <div className="relative flex items-center mr-2">
                      <span className="absolute left-3 text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.9 14.32a8 8 0 111.42-1.42l4.31 4.3a1 1 0 01-1.42 1.42l-4.3-4.31zM8 14a6 6 0 100-12 6 6 0 000 12z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        placeholder="Search for a player..."
                        value={searchTerm ? searchTerm : ""}
                        onChange={(e) => handleFilterSearch(e.target.value)}
                        className={`text-white pl-10 py-2 rounded-[50px] border border-[${Colors.backgroundSecundary}] focus:outline-none bg-[${Colors.backgroundSecundary}]`}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="pl-5 overflow-x-auto no-scrollbar">
                  <TableTabs
                    tabItems={positions}
                    tabCustomStyle="w-15.5 h-11 shrink-0"
                    onTabSelect={handleSelectPosition}
                    resetTabs={true}
                  />
                </div>

                <TablePlayers
                  tableHeader={["Players"]}
                  data={playersData}
                  selected={false}
                  disabled={disabledAdd}
                  onSelectPlayer={(player: DataItem) => {
                    handleSelectPlayer(player);
                  }}
                />
              </div>
              {tradeScreen === "TradeAway" &&
                playersDataTradeAway.length > 0 && (
                  <Button
                    className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[90%] h-[60px] text-base mb-6"
                    onClick={() => handleButtonNext()}
                  >
                    Next
                  </Button>
                )}

              {tradeScreen === "TradeFor" && playersDataTradeFor.length > 0 && (
              <Button
                className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[90%] h-[60px] text-base mb-6"
                onClick={() => handleButtonCalculate()}
              >
                Calculate Now
              </Button>
              )}
            </div>
          </div>
        )}
        {tradeScreen === "Calculating" && <WaveProgressBar />}
      </Layout>
      <AnimatePresence>
      {tradeScreen === "Report" && (
        <motion.div
          className="fixed top-0 right-0 w-full z-30 h-screen overflow-y-auto capabilities-bg"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
        >
          <TradeReport
            playersDataTradeAway={playersDataTradeAway}
            playersDataTradeFor={playersDataTradeFor}
            report={report}
            tradeOutcome={tradeOutcome}
            onReset={handleOnReset}
          />
        </motion.div>
      )}
      </AnimatePresence>
    </>
  );
};

export default TradeAnalyzer;
