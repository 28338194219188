import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Paragraph } from "../Commons/Paragraph";
import { Button } from "../ui/button";
import LottieAnimation from "../Commons/LottieAnimation/LottieAnimation";
import chatbotAnimation from "../../assets/animations/Chatbot.json";

interface ScoreModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ScoreModal: React.FC<ScoreModalProps> = ({ isVisible, onClose }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-50 flex justify-center"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          onClick={onClose}
        >
          <motion.div
            className="text-white flex justify-center items-center h-full p-[14px] max-w-[500px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div
              style={{
                borderRadius: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.15)",
              }}
              className="text-center p-6 backdrop-blur-[25px] transition-all duration-500"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="rounded-full w-[100.92px] h-[100.92px] sm:hidden mb-6 mx-auto">
                <LottieAnimation animationData={chatbotAnimation} />
              </div>
              <Paragraph
                text="RotoBot Score"
                ParagraphType="Paragraph22"
                color="textPrimary"
                className="mb-1.5 text-3xl !font-semibold"
              />
              <Paragraph
                text="The RotoBot Score is our custom metric that combines player stats, usage, and team context to give you a quick, clear snapshot of a player’s fantasy value. Use it to make smarter decisions on who to start, trade, or pick up."
                props={{
                  fontSize: "14px",
                  fontWeight: "400",
                  opacity: "0.50",
                }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
              <Button
                className="text-base font-medium mt-10 mb-3 rounded-[10px]"
                onClick={onClose}
              >
                Got it
              </Button>
            </div>
          </motion.div>
        </motion.div>
      )}
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-20"
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
            WebkitBackdropFilter: "blur(9px)",
          }}
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
        ></motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScoreModal;
