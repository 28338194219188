import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  isSupported,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
import { Capacitor } from "@capacitor/core";
import { firebaseConfig } from "./firebaseConfig";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

let analytics: any = null;

export const initializeFirebase = async () => {
  if (Capacitor.getPlatform() === "web") {
    if (await isSupported()) {
      const app = initializeApp(firebaseConfig);
      const anal = getAnalytics(app);
      setAnalyticsCollectionEnabled(anal, true);
      return anal;
    }
    return null;
  } else {
    await FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
    
    const anal = await FirebaseAnalytics.initializeFirebase({
      apiKey: firebaseConfig.apiKey,
      authDomain: firebaseConfig.authDomain,
      projectId: firebaseConfig.projectId,
      storageBucket: firebaseConfig.storageBucket,
      messagingSenderId: firebaseConfig.messagingSenderId,
      appId: firebaseConfig.appId,
      measurementId: firebaseConfig.measurementId,
    });
    return anal;
  }
};

export const getAnalyticsInstance = async () => {
  analytics = await initializeFirebase();
  return analytics;
};
