import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const convertDateFormat = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return `${day}-${month}-${year}`;
};

export const parseDate = (dateString: string) => {
  const [day, month, year] = dateString.split(/[-/]/);
  return new Date(`${year}-${month}-${day}`);
};

export function snakeToTitleCase(snakeStr: string) {
  return snakeStr
    .split("_")
    .map(
      (word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(" ");
}

export const capitalizeFirstLetter = (text: string) => {
  try {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } catch (err) {
    console.log("error in capitalizeFirstLetter", err);
    return "";
  }
};

export const abbreviateName = (fullName: string): string => {
  const [firstName, lastName] = fullName.split(" ");
  if (!firstName || !lastName) return fullName;
  return `${firstName.charAt(0).toUpperCase()}. ${lastName}`;
};

export const getInitials = (fullName?: string | undefined | null) => {
  if (!fullName) {
    return "";
  }

  const nameParts = fullName.trim().split(" ");

  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  } else if (nameParts.length >= 2) {
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[1].charAt(0).toUpperCase()
    );
  }
};

export const validateEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

export const convertInchesToFeet = (inches: number): string => {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  const formattedInches =
    remainingInches === Math.floor(remainingInches)
      ? remainingInches.toString()
      : remainingInches.toFixed(1);
  return `${feet}'${formattedInches}`;
};

export const statusBarThemeColor = () => {
  let metaThemeColor = document.querySelector(
    'meta[name="theme-color"]',
  ) as HTMLMetaElement;

  if (!metaThemeColor) {
    metaThemeColor = document.createElement("meta");
    metaThemeColor.name = "theme-color";
    document.head.appendChild(metaThemeColor);
  }
  metaThemeColor.setAttribute("content", "#120F2C");
  return () => {
    metaThemeColor.setAttribute("content", "#ffff");
  };
};
export const commonHeaders = ["WK", "OPP", "SCORE", "FPTS", "RANK"];
export const passingHeaders = ["YDS", "TDS", "FUM", "ATT", "CMP", "INT"];
export const rushingHeaders = [
  "RSH ATT",
  "+ RSH ATT",
  "RSH YDS",
  "RSH TDS",
  "FUM",
];
export const receivingHeaders = [
  "TAR",
  "REC",
  "REC YDS",
  "REC TDS",
  "RET",
  "RET YDS",
];
export const returnHeaders = ["RET", "RET YDS", "RET TDS"];

export const renderQBStats = (stats: any, key: string) => {
  if (!stats || stats.length === 0) return "-"; // Handle missing stats
  switch (key) {
    case "YDS":
      return stats[0]?.pass_yards ? stats[0].pass_yards : "0";
    case "TDS":
      return stats[0]?.passing_touchdowns || "0";
    case "FUM":
      return stats[0]?.fumbles_lost || "0";
    case "ATT":
      return stats[0]?.pass_attempts || "0";
    case "CMP":
      return stats[0]?.completions || "0";
    case "INT":
      return stats[0]?.interceptions || "0";

    case "RSH YDS":
      return stats[1]?.rush_yards || "0";
    case "RSH TDS":
      return stats[1]?.rushing_touchdowns || "0";
    case "RSH ATT":
      return stats[1]?.rushing_attempts || "0";
    case "+ RSH ATT":
      return stats[1]?.successful_rush_attempts || "0";

    case "RET":
      return stats[1]?.returns || "0";
    case "RET YDS":
      return stats[1]?.return_yards || "0";
    case "RET TDS":
      return stats[1]?.return_tds || "0";

    default:
      return "-";
  }
};

export const renderRBStats = (stats: any, key: string) => {
  if (!stats || stats.length === 0) return "-"; // Handle missing stats
  switch (key) {
    case "RSH YDS":
      return stats[0]?.rush_yards || "0";
    case "RSH TDS":
      return stats[0]?.rushing_touchdowns || "0";
    case "FUM":
      return stats[0]?.fumbles_lost || "0";
    case "RSH ATT":
      return stats[0]?.rushing_attempts || "0";
    case "+ RSH ATT":
      return stats[0]?.successful_rush_attempts || "0";

    case "TAR":
      return stats[1]?.receiving_targets || "0";
    case "REC":
      return stats[1]?.receptions || "0";
    case "REC YDS":
      return stats[1]?.receiving_yards || "0";
    case "REC TDS":
      return stats[1]?.receiving_touchdowns || "0";

    case "RET":
      return stats[0]?.returns || "0";
    case "RET YDS":
      return stats[0]?.return_yards || "0";
    case "RET TDS":
      return stats[0]?.return_tds || "0";

    default:
      return "-";
  }
};

export const renderWR_TEStats = (stats: any, key: string) => {
  if (!stats || stats.length === 0) return "-"; // Handle missing stats
  switch (key) {
    case "REC YDS":
      return stats[0]?.receiving_yards || "0";
    case "REC TDS":
      return stats[0]?.receiving_touchdowns || "0";
    case "FUM":
      return stats[0]?.fumbles_lost || "0";
    case "TAR":
      return stats[0]?.receiving_targets || "0";
    case "REC":
      return stats[0]?.receptions || "0";

    case "RSH YDS":
      return stats[1]?.rush_yards || "0";
    case "RSH TDS":
      return stats[1]?.rushing_touchdowns || "0";
    case "RSH ATT":
      return stats[1]?.rushing_attempts || "0";
    case "+ RSH ATT":
      return stats[1]?.successful_rush_attempts || "0";

    case "RET":
      return stats[1]?.returns || "0";
    case "RET YDS":
      return stats[1]?.return_yards || "0";
    case "RET TDS":
      return stats[1]?.return_tds || "0";

    default:
      return "-";
  }
};

export const fetchFieldValue = (item: any, header: string) => {
  switch (header) {
    case "Player Name":
      return item.playerName ? item.playerName : "-";
    case "RotoBot Score":
      return item.playerTradeValue != null ? item.playerTradeValue : "-";
    case "Positional Rank":
      return item.positionRank != null ? item.positionRank : "-";
    case "Opponent":
      return item.nextOpponent != null ? item.nextOpponent : "-";
    case "Projected FP":
      return item.weeklyFPProj != null ? item.weeklyFPProj : "-";
    default:
      return "-";
  }
};

export const formatDate = (dateString: string) => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifference = currentDate.getTime() - inputDate.getTime();

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    return hoursDifference < 24 ? "Today" : "1d";
  } else if (daysDifference > 0) {
    return `${daysDifference}d`;
  }
};

export const headerAccordingPlayerPosition = (playerPosition: string) => {
  switch (playerPosition) {
    case "QB":
      return [
        { id: 1, name: "FANTASY" },
        { id: 2, name: "PASSING" },
        { id: 3, name: "RUSHING" },
      ];
    case "RB":
      return [
        { id: 1, name: "FANTASY" },
        { id: 2, name: "RUSHING" },
        { id: 3, name: "RECEIVING" },
      ];
    case "WR":
    case "TE":
      return [
        { id: 1, name: "FANTASY" },
        { id: 2, name: "RECEIVING" },
        { id: 3, name: "RUSHING" },
      ];
    default:
      return [
        { id: 1, name: "FANTASY" },
        { id: 2, name: "PASSING" },
        { id: 3, name: "RUSHING" },
      ];
  }
};

export const getSuggestionsTitle = (name: string) => {
  switch (name) {
    case "season_outlook":
      return "Season Outlook";
    case "season_recap":
      return "Season Recap";
    case "trade_analyzer":
      return "Trade Analysis";
    case "waiver_wire":
      return "Waiver Assistant";
    case "sit_start":
      return "Sit/Start Analysis";
    case "weekly_outlook":
      return "Weekly Outlook";
    default:
      return "Suggested Question";
  }
};

export const getFormattedDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const options = { month: "long" } as const;
  const monthName = new Intl.DateTimeFormat("en-US", options).format(date);
  const day = date.getDate();

  const getOrdinalSuffix = (n: number): string => {
    const j = n % 10,
      k = n % 100;
    if (j === 1 && k !== 11) {
      return n + "st";
    }
    if (j === 2 && k !== 12) {
      return n + "nd";
    }
    if (j === 3 && k !== 13) {
      return n + "rd";
    }
    return n + "th";
  };

  const dayWithSuffix = getOrdinalSuffix(day);
  return `${monthName} ${dayWithSuffix}`;
};
