import HttpRequestPlayerProfile from "../../Services/HttpRequestPlayerProfile";
import { useQuery } from "@tanstack/react-query";

export const getRankingThisWeek = async () => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "GET",
      url: "/v1/rankings_this_week/",
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const useRankingThisWeek = () => {
  return useQuery({
    queryKey: ["getRankingThisWeek"],
    queryFn: getRankingThisWeek,
    staleTime: 15 * 60 * 1000, //15 minutes
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
};

export const getROSRankings = async () => {
  try {
    const response = await HttpRequestPlayerProfile({
      method: "GET",
      url: "/v1/rankings_ros/",
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const useROSRankings = () => {
  return useQuery({
    queryKey: ["getROSRankings"],
    queryFn: getROSRankings,
    staleTime: 15 * 60 * 1000, //15 minutes
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
};