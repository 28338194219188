import React, { useState, useEffect } from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import email_icon from "../../../assets/imgs/emailIcon.svg";
import first_name_icon from "../../../assets/imgs/first-name-icon.svg";
import last_name_icon from "../../../assets/imgs/last-name-icon.svg";
import warning_icon from "../../../assets/imgs/warning-icon.svg";
import team_Analyzer from "../../../assets/imgs/team-analyzer.svg";
import { Input } from "../../ui/input";
import DatePickerComponent from "../../Commons/DatePicker/DatePicker";
import { validateEmail } from "../../../lib/utils";

interface UserDetailsCardProps {
  email: string;
  firstName: string;
  lastName: string;
  referralCode: string;
  showError: boolean;
  onChange: (field: string, value: string) => void;
}

const UserDetailsCard: React.FC<UserDetailsCardProps> = ({
  email,
  firstName,
  lastName,
  referralCode,
  showError,
  onChange,
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onChange("birthday", date ? date.toISOString() : "");
  };

  const maskEmail = (email: string) => {
    return `example@email.com`;
  };
  const maskFirstName = (name: string) => {
    return `Enter your First Name`;
  };
  const maskLastName = (name: string) => {
    return `Enter your Last Name`;
  };

  const maskReferralCode = (code: string) => {
    return `(optional)`;
  };

  const renderMaskedText = (
    show: boolean,
    text: string,
    maskFn: (text: string) => string,
  ) => (show ? text : maskFn(text));

  const renderInfoRow = (
    label: string,
    text: string,
    show: boolean | null,
    toggleShow?: () => void,
    maskFn?: (text: string) => string,
    lastChild = false,
  ) => (
    <div
      className="flex items-center py-4"
      style={{
        borderBottom: lastChild
          ? "none"
          : `1px solid ${Colors.backgroundSecundary}`,
      }}
    >
      {label === "First Name" && <img src={first_name_icon} alt="" className=" mr-3" />}
      {label === "Last Name" && <img src={last_name_icon} alt="" className=" mr-3" />}
      {label === "Email" && <img src={email_icon} alt="" className=" mr-3" />}
      {label === "Referral Code" && (
        <img src={team_Analyzer} alt="" className=" mr-3" />
      )}

      <Paragraph
        text={label}
        props={{
          fontSize: "15px",
          fontWeight: 600,
          letterSpacing: "-0.5px",
          minWidth: "105px",
          textAlign: "left",
        }}
        ParagraphType="Paragraph22"
        color="textPrimary"
      />

      <div className="ml-auto text-gray-400 relative">
        {label === "Birthday" ? (
          <DatePickerComponent
            selected={selectedDate}
            onChange={handleDateChange}
            customInput={
              <button className="example-custom-input">
                {selectedDate
                  ? selectedDate.toLocaleDateString()
                  : "MM/DD/YYYY"}
              </button>
            }
          />
        ) : (
          <Input
            className="border-0 bg-transparent px-1 outline-0 h-10 placeholder:text-right text-[17px] text-right"
            value={text}
            onChange={(e) => onChange(label.toLowerCase(), e.target.value)}
            placeholder={
              show !== null && maskFn
                ? renderMaskedText(show, text, maskFn)
                : text
            }
          />
        )}
        {label != "Referral Code" &&
          showError &&
          (text.trim() === "" ||
            (label === "Email" && !validateEmail(text))) && (
            <div className=" float-right flex absolute right-2 -bottom-[17px] ">
              <Paragraph
                text={"Invalid " + label}
                props={{
                  fontSize: "15px",
                  fontWeight: 600,
                  color: Colors.textRed,
                }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
              <img src={warning_icon} alt="" className=" ml-3" />
            </div>
          )}
      </div>
    </div>
  );

  return (
    <div
      className="max-w-full min-w-full mx-auto text-white rounded-[18px] px-[14px] py-1"
      style={{ backgroundColor: Colors.backgroundSecundary }}
    >
      {renderInfoRow("First Name", firstName, false, undefined, maskFirstName)}
      {renderInfoRow("Last Name", lastName, false, undefined, maskLastName)}
      {renderInfoRow("Email", email, false, undefined, maskEmail)}
      {renderInfoRow(
        "Referral Code",
        referralCode,
        false,
        undefined,
        maskReferralCode,
        true,
      )}
    </div>
  );
};

export default UserDetailsCard;
