import { useCallback } from "react";
import { getAnalyticsInstance } from "./firebase";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

const useFirebaseAnalytics = () => {
  const logEventAnalytics = useCallback(
    async (name: string, params?: { [key: string]: any }) => {
      if (Capacitor.getPlatform() === "web") {
        const analytics: any = await getAnalyticsInstance();

        if (analytics) {
          logEvent(analytics, name, params);
        }
      } else {
        await FirebaseAnalytics.logEvent({ name, params: params || {} });
      }
    },
    [],
  );

  const setUserIdAnalytics = useCallback(async (userId: string) => {
    if (Capacitor.getPlatform() === "web") {
      const analytics: any = await getAnalyticsInstance();
      if (analytics) {
        setUserId(analytics, userId);
      }
    } else {
      await FirebaseAnalytics.setUserId({ userId });
    }
  }, []);

  const setUserPropertiesAnalytics = useCallback(
    async (properties: { [key: string]: any }) => {
      if (Capacitor.getPlatform() === "web") {
        const analytics: any = await getAnalyticsInstance();
        if (analytics) {
          setUserProperties(analytics, properties);
        }
      } else {
        await FirebaseAnalytics.setUserProperty({
          name: "",
          value: JSON.stringify(properties),
        });
      }
    },
    [],
  );

  return {
    logEventAnalytics,
    setUserIdAnalytics,
    setUserPropertiesAnalytics,
  };
};

export default useFirebaseAnalytics;
