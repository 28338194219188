import React, { useState, useEffect } from "react";
import Null_player from "../../../assets/imgs/null-player-img.svg";
import { Colors } from "../../Commons/Colors/Colors";
import Daimond_icon from "../../../assets/imgs/Vector.svg";
import BotIcon from "../../../assets/imgs/logo-02 2.svg";
import ChatIcon from "../../../assets/imgs/chat-plus-icon.svg";
import { Paragraph } from "../../Commons/Paragraph";
import CharPersonIcon from "../../../assets/imgs/player-analyzer.svg";
import DataBaseIcon from "../../../assets/imgs/team-analyzer.svg";
import PeopleComunityIcon from "../../../assets/imgs/sit-start-decider.svg";
import PersonQuestionIcon from "../../../assets/imgs/waiver-assistant.svg";
import DraftAnalyzer from "../../../assets/imgs/draft-analyzer.svg";
import TradeAnalyzer from "../../../assets/imgs/trade-analyzer.svg";
import { dataToday, dataYesterday } from "../MenuMobile/data";
import useUserProfile from "../../../hooks/useUserProfile";
import { Button } from "../../ui/button";
import { useSupabaseAuth } from "../../../Context/AuthContext/AuthContext";
import { fetchHistoryChatsByUser } from "../../../Controllers/ChatRotobot";
import MenuSiderItem from "./MenuSiderItem";
import { isMIUI, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const SidebarItem: React.FC<{ href: string; imgSrc: string; text: string }> = ({
  href,
  imgSrc,
  text,
}) => (
  <li>
    <a href={href} className="flex items-center">
      <div
        className="mr-2 rounded-full w-[40px] md:w-[45px] xl:w-[50px] h-[40px] md:h-[45px] xl:h-[50px] flex justify-center items-center"
        style={{ background: Colors.backgroundSecundary }}
      >
        <img src={imgSrc} alt="" className="w-[22px] md:w-[26px] xl:w-[30px]" />
      </div>
      <Paragraph
        text={text}
        ParagraphType="PragraghSemiBold400"
        color="textPrimary"
        className="text-sm xl:text-base font-medium"
      />
    </a>
  </li>
);

const ChatbotSidebar: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const { firstName, profile } = useUserProfile();
  const { getUserData } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [chatHistories, setChatHistories] = useState<any>([]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const response = await getUserData();

      if (response) {
        setCurrentUser(response?.data?.user);
      }
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const historyChats = async () => {
      const response = await fetchHistoryChatsByUser(currentUser?.id);
      if (response?.status) {
        setChatHistories(response.data?.chats);
      } else {
        setChatHistories([]);
      }
    };
    if (currentUser?.id && !isMobile) {
      historyChats();
    }
  }, [currentUser]);

  console.log("currentUser", currentUser);

  return (
    <div className="h-full sm:block hidden text-white bg-[#31374878] w-[240px] lg:w-[280px]">
      <div className={`flex h-full flex-col`}>
        <div
          className={`flex items-end justify-between border-b-2 p-4 h-24`}
          style={{ borderColor: Colors.backgroundSecundary }}
        >
          <div className="flex flex-row gap-4 lg:gap-5 xl:gap-8 items-center">
            <img src={BotIcon} alt="logo" className=" h-12 md:h-14 xl:h-15 " />
            <div
              className="cursor-pointer flex items-center shrink-0"
              onClick={() => navigate("/chat/new")}
            >
              <img src={ChatIcon} alt="logo" className=" w-5 md:w-6 xl:w-8 " />
            </div>
          </div>
        </div>
        <div className="overflow-y-auto  h-[69vh] no-scrollbar ">
          {/* TODO decide if we want to keep this sidebar or not */}
          {/* <div className={`pt-3 `}>
            <div className="h-full flex items-end">
              <div id="options-wrapper" className={`mx-[18px] w-full my-4`}>
                <ul className="space-y-3">
                  <SidebarItem
                    href="/home"
                    imgSrc={DraftAnalyzer}
                    text="Draft Analyzer"
                  />
                  <SidebarItem
                    href="/home"
                    imgSrc={CharPersonIcon}
                    text="Player Analyzer"
                  />
                  <SidebarItem
                    href="/home"
                    imgSrc={TradeAnalyzer}
                    text="Trade Analyzer"
                  />
                  <SidebarItem
                    href="/team-analyzer"
                    imgSrc={DataBaseIcon}
                    text="Team Analyzer"
                  />
                  <SidebarItem
                    href="/home"
                    imgSrc={PeopleComunityIcon}
                    text="Sit/Start Decider"
                  />
                  <SidebarItem
                    href="/home"
                    imgSrc={PersonQuestionIcon}
                    text="Waiver Assistant"
                  />
                </ul>
              </div>
            </div>
          </div> */}
          <MenuSiderItem chatHistories={chatHistories} />
        </div>
        <div className={`w-full p-4 mt-auto bg-[#FFFFFF0F]`}>
          <Button className="w-full py-2 mb-4 text-center gap-1 justify-center  rounded-3xl flex flex-row h-[50px]">
            <img className="w-4 h-5 " src={Daimond_icon} alt="diamond" />
            Go Premium
          </Button>
          <div className="flex items-center">
            <div
              className="w-8 md:w-10 xl:w-12 h-8 md:h-10 xl:h-12 border-[2px] rounded-full flex justify-center items-center"
              onClick={() => navigate("/user-profile")}
              style={{ borderColor: Colors.backgroundPrimary }}
            >
              <img
                src={
                  currentUser?.user_metadata?.avatar_url
                    ? currentUser?.user_metadata?.avatar_url
                    : Null_player
                }
                alt="Profile"
                className={
                 "w-full h-full rounded-full object-cover cursor-pointer"
                }
              />
            </div>
            <span className="ml-4">
              {currentUser?.user_metadata?.full_name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatbotSidebar;
