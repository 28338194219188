import { Paragraph } from "../../Commons/Paragraph";

//icons
import ArrowBackIcon from "../../../assets/imgs/arrow-back-left.svg";
import MenuLineIcon from "../../../assets/imgs/menu-line.svg";

//style
import { HeaderStyle } from "./HeaderStyle";

import { HeaderProps } from "./type";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../Commons/Colors/Colors";
import useFirebaseAnalytics from "../../../hooks/useFirabaseAnality";
import { useLocation } from "react-router-dom";

export const Header: React.FC<HeaderProps> = ({ onClickMenu }) => {
  const navigate = useNavigate();
  const { id, playerId } = useParams();
  const { logEventAnalytics } = useFirebaseAnalytics();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const route = params.get("route");

  const handleGoBack = () => {
    if (playerId) {
      logEventAnalytics("chat_nav_back ", {
        name: "Chatbot return back",
        playerID: playerId,
      });
      if (route === "ranking") {
        navigate(
          `/player-profile/${playerId}${route ? `?route=${route}` : ""}`,
        );
      } else {
        navigate(`/player-profile/${playerId}`);
      }
    } else {
      logEventAnalytics("chat_nav_back ", {
        name: "Chatbot return back",
      });
      navigate("/home");
    }
  };

  return (
    <div className="flex items-center justify-between px-5 pt-5">
      <div
        className="cursor-pointer relative z-10"
        style={HeaderStyle.icon}
        onClick={handleGoBack}
      >
        <img src={ArrowBackIcon} alt="" />
      </div>
      <div className=" text-[22px] font-medium chatbot-header-text">
        RotoBot v1.0
      </div>
      <div
        className="cursor-pointer relative z-10"
        style={HeaderStyle.icon}
        onClick={onClickMenu}
      >
        <img src={MenuLineIcon} alt="" />
      </div>
      {/* <div className="text-xl"> Icono 2</div> */}
    </div>
  );
};
