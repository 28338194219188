import { waveform } from "ldrs";
import { Paragraph } from "../../Commons/Paragraph";

waveform.register();

const WaveProgressBar = () => {
  return (
    <div className="flex justify-center flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="p-6">
        <l-waveform size="70" stroke="5.5" speed="1.2" color="#6c2ce4"></l-waveform>
      </div>
      <Paragraph
        text={'Calculating' }
        props={{ fontSize: "20px", fontWeight: "600" }}
        ParagraphType="Paragraph22"
        color="textPrimary"
        />
    </div>
  );
};

export default WaveProgressBar;
