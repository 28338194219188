import React, { ReactNode, useState, useEffect, forwardRef } from "react";
import { useSafeAreaInsets } from "../../Context/SafeAreaContext/SafeAreaContext";
import PopUp from "../PopUp/PopUp";
import { statusBarThemeColor } from "../../lib/utils";

export interface LayoutProps {
  children: ReactNode;
}

export const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ children }, ref) => {
    const { top, bottom, left, right } = useSafeAreaInsets();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pageViews, setPageViews] = useState(0);

    useEffect(() => {
      const pathname = window.location.pathname;

      if (pathname === '/home') {
        const storedPageViews = localStorage.getItem('pageViews');

        if (storedPageViews) {
          setPageViews(parseInt(storedPageViews, 10));
        }

        setPageViews((prevPageViews) => {
          const newPageViews = prevPageViews + 1;
          localStorage.setItem('pageViews', newPageViews.toString());
          return newPageViews;
        });
      }
      statusBarThemeColor()
    }, []);

    useEffect(() => {
      if (pageViews === 6) {
        setIsModalVisible(true);
      }
    }, [pageViews]);

    const toggleModal = () => setIsModalVisible(!isModalVisible);

    return (
      <div
        ref={ref}
        style={{
          paddingTop: top,
          paddingBottom: bottom,
          paddingLeft: left,
          paddingRight: right,
          boxSizing: "border-box",
          width: "100vw",
          overflowY: "auto",
          height:"100svh",
          overflowX: "hidden"
        }}
      >
        <div className="p-5 h-full w-full">{children}</div>
        <PopUp isVisible={isModalVisible} onClose={toggleModal} />
      </div>
    );
  }
);

Layout.displayName = "Layout";
