import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { Colors } from "../Commons/Colors/Colors";
import OTPInputComponent from "../Commons/OtpInput/OtpInput";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { Button } from "../ui/button";

import { supabase } from "../../supabase/supabase.config";

export interface VerififcationOtpProps {
  phone: string;
  onVerifyOTPCode: (phone: string, token: string) => void;
  onResendCode: () => void;
  localError: string | null;
  setLocalError: (error: string | null) => void;
}

export const VerififcationOtp: React.FC<VerififcationOtpProps> = ({
  phone,
  onVerifyOTPCode,
  onResendCode,
  localError,
  setLocalError,
}) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string>("");
  const numInputs = 6;

  const handleOTPChange = (otp: string) => {
    setToken(otp);
    setLocalError(null);
  };

  const handleCheckOtp = () => {
    setLocalError(null);
    onVerifyOTPCode(phone, token);
  };

  const handleResendCode = () => {
    onResendCode();
  };

  const lastThreeDigits = phone.slice(-4);

  return (
    <div className="w-full h-full flex text-center justify-center ">
      <div className="flex flex-col max-h-screen sm:w-1/2 w-full">
        <div className="flex items-center justify-center">
          <div></div>
        </div>
        <div className="mt-20">
          <div className="sm:text-5xl text-4xl font-bold text-white">
            Activate Account
          </div>

          <div
            className=" w-full mt-3 text-xl justify-center sm:text-3xl"
            style={{
              color: Colors.textLigth,
            }}
          >
            Enter the verification code sent to.
          </div>
          <div className="text-xl sm:text-3xl text-center text-white">
            *********{lastThreeDigits}
          </div>
          <div className="mt-5 w-full flex justify-center">
            <OTPInputComponent
              value={token}
              onChange={handleOTPChange}
              numInputs={numInputs}
              correctOTP={localError}
            />
          </div>
          {localError && <p className="text-red-500 mt-4">{localError}</p>}
          <div className="mt-4">
            <Button
              variant={"link"}
              size={"default"}
              onClick={handleResendCode}
              className="text-xl"
            >
              Send a new code
            </Button>
          </div>
        </div>
        <div className="mt-8 ">
          <Button onClick={handleCheckOtp} className="w-1/2">
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
