import React, { useRef, useState, useEffect } from "react";
import PlayerDetails from "../PlayersDetail/PlayersDetail";
import profile_img from "../../.././assets/imgs/player_img.svg";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import Null_player from "../../../assets/imgs/null-player-img.svg";
import { convertInchesToFeet, formatDate } from "../../../lib/utils";
import { ping, cardio } from "ldrs";

ping.register();
cardio.register();

interface Props {
  setScrollPosition: any;
  scrollPosition: number;
  player: any;
}

const getPlayerStatusText = (status?: string) => {
  switch (status) {
    case "ACT":
      return "Active";
    case "INA":
    case "RES":
      return "Out";
    case "QUES":
      return "Ques";
    default:
      return "--";
  }
};

const getPlayerStatusIcon = (status?: string) => {
  switch (status) {
    case "ACT":
      return <l-ping size="45" speed="4" color="#70F1D9" />;
    case "QUES":
      return <l-ping size="45" speed="4" color="#ffbb00" />;
    default:
      return (
        <div className="my-2 ml-2">
          <l-cardio size="20" speed="2" color="#ff006f" stroke={2.5} />
        </div>
      );
  }
};

const PlayerScrollView = ({
  setScrollPosition,
  scrollPosition,
  player,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLastVisible, setIsLastVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollLeft = containerRef.current.scrollLeft;
        setScrollPosition(scrollLeft);

        const maxScrollLeft =
          containerRef.current.scrollWidth - containerRef.current.clientWidth;
        setIsLastVisible(scrollLeft >= maxScrollLeft - 150);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);
  
  return (
    <div className="relative -mx-5">
      <div
        ref={containerRef}
        className="flex overflow-x-auto sm:overflow-x-hidden no-scrollbar relative z-20 w-screen sm:w-[calc(100vw_-320px)]"
        style={{ scrollSnapType: "x mandatory" }}
      >
        <div className="flex w-[100vw] sm:w-[calc(100vw_-320px)] flex-2">
          <div
            className="w-[50vw] sm:w-[calc(50vw_-120px)] lg:sm:w-[calc(50vw_-_140px)] flex-shrink-0 transform transition-transform duration-500 flex-1 pl-5 sm:pl-7 md:pl-8 lg:10 relative z-30 flex flex-col justify-between"
            style={{
              scrollSnapAlign: "start",
              opacity: isLastVisible ? 0 : 1,
              transition: "opacity 0.4s ease-in-out",
            }}
          >
            <div className="mt-6 lg:mb-8">
              <PlayerDetails
                name={`${player?.playerFirstName || "--"} ${player?.playerLastName || "--"} `}
                position={
                  player
                    ? `${player.playerTeamShort} • ${player.playerPosition} • ${"#" + player.playerNumber}`
                    : "--"
                }
                posRank={player?.playerPositionRank}
                ovrRank={player?.playerOverallRank}
                status={player?.playerHealthStatus}
                playerPos={player?.playerPosition}
              />
              <div className="mt-10 hidden sm:flex items-center gap-6 text-white">
                <Paragraph
                  text={"ADP"}
                  className="text-2xl sm:text-2xl md:text-3xl lg:text-[34px] !font-semibold leading-[100%]"
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <div
                  className={`p-[10px] px-8 rounded-xl inline-flex gap-4 items-center bg-blur`}
                  style={{
                    backgroundColor: Colors.backgroundSecundary,
                  }}
                >
                  <Paragraph
                    text={
                      player?.playerPerformanceScores?.playerValues?.playerADP
                        ? player?.playerPerformanceScores?.playerValues
                          ?.playerADP
                        : "--"
                    }
                    className="text-2xl sm:text-3xl md:text-4xl lg:text-[42.287px] !font-semibold leading-[100%]"
                    ParagraphType="Pragragh22"
                    color="textPrimary"
                  />
                </div>
              </div>
            </div>
            <div className="bg-blur px-2 py-1 inline-block w-fit mb-4 rounded-[5px]">
              <Paragraph
                text={`Last Updated: ${formatDate(player?.version)}`}
                className="text-[7.5px] md:text-xs lg:text-sm opacity-70 whitespace-nowrap"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
          </div>
          <div
            className={`selectDisable w-[50vw] sm:w-[calc(50vw_-120px)] lg:sm:w-[calc(50vw_-_140px)] flex-shrink-0 transform transition-transform duration-500 flex-1 pr-5 flex sm:justify-center`}
            style={{ scrollSnapAlign: "start" }}
          >
            <img
              src={player?.playerheadshotURL || Null_player}
              alt=""
              className={`${player?.playerheadshotURL && "-ml-[100px]"} ${player?.playerheadshotURL ? "min-w-[406px] md:min-w-[526.52px] lg:min-w-[586.52px] min-h-[280.016px] lg:min-h-[443.08px]" : "min-w-[246.45px] min-h-[235.582px]"} relative z-20`}
              onError={(e) => {
                (e.currentTarget as HTMLImageElement).src = Null_player;
              }}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </div>
        <div className="w-[50vw] sm:w-[calc(50vw_-120px)] lg:sm:w-[calc(50vw_-_140px)] flex-shrink-0 transition-opacity duration-500 relative z-80 sm:hidden"></div>
      </div>

      <div
        className="w-[50vw] sm:w-[calc(50vw_-120px)] lg:sm:w-[calc(50vw_-_140px)] mb-6 absolute right-0 bottom-0 flex justify-end sm:justify-center pr-5 ml-auto sm:hidden"
        style={{
          scrollSnapAlign: "start",
          opacity: isLastVisible ? 1 : 0,
          zIndex: isLastVisible ? 20 : 0,
          transition: "opacity 0.4s ease-in-out",
        }}
      >
        <div>
          <Paragraph
            text={"Player Details"}
            className="text-2xl sm:text-3xl md:text-4xl lg:text-[40.091px] !font-bold !leading-[120%]"
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <div className="flex items-center">
            <Paragraph
              text={`Status: ${getPlayerStatusText(player?.playerHealthStatus)}`}
              className="text-base sm:text-[18px] md:text-[20px] lg:text-[24.091px] !font-semibold leading-[100%] opacity-70"
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
            {getPlayerStatusIcon(player?.playerHealthStatus)}
          </div>
          <div
            className={`p-[10px] rounded-xl flex gap-4 items-center mt-1 bg-blur`}
            style={{
              backdropFilter: "blur(4.5px)"
            }}
          >
            <div className=" min-w-14 sm:min-w-[80px] md:min-w-[110px] text-center">
              <Paragraph
                text={convertInchesToFeet(player?.playerHeight) || "--"}
                className="text-[28px] md:text-3xl lg:text-[37.091px] !font-semibold leading-[100%]"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
              <Paragraph
                text="Height"
                className="text-[10px] md:text-xs lg:text-sm opacity-70"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
            <div
              className="h-[35.5px] w-[1px]"
              style={{ backgroundColor: Colors.backgroundSecundary }}
            ></div>
            <div className=" min-w-14 sm:min-w-[80px] md:min-w-[110px] text-center">
              <Paragraph
                text={
                  player?.playerWeight.toString().replace("lbs", "") || "--"
                }
                className="text-[28px] md:text-3xl lg:text-[37.091px] !font-semibold leading-[100%]"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
              <Paragraph
                text="Weight"
                className="text-[10px] md:text-xs lg:text-sm opacity-70"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
          </div>
          <div
            className={`p-[10px] rounded-xl flex gap-4 items-center mt-4 bg-blur`}
            style={{
              backdropFilter: "blur(4.5px)"
            }}
          >
            <div className=" min-w-14 sm:min-w-[80px] md:min-w-[110px] text-center">
              <Paragraph
                text={player?.playerAge || "--"}
                className="text-[28px] md:text-3xl lg:text-[37.091px] !font-semibold leading-[100%]"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
              <Paragraph
                text="Age"
                className="text-[10px] md:text-xs lg:text-sm opacity-70"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
            <div
              className="h-[35.5px] w-[1px]"
              style={{ backgroundColor: Colors.backgroundSecundary }}
            ></div>
            <div className="min-w-14 sm:min-w-[80px] md:min-w-[110px] text-center">
              <Paragraph
                text={
                  player?.playerExperience !== undefined
                    ? player.playerExperience + "yr"
                    : "--"
                }
                className="text-[28px] md:text-3xl lg:text-[37.091px] !font-semibold leading-[100%]"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />

              <Paragraph
                text="Exp"
                className="text-[10px] md:text-xs lg:text-sm opacity-70"
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-2 justify-end gap-1 absolute bottom-2 left-1/2 -translate-x-1/2 z-40 sm:hidden">
        <div
          className=" w-2 h-2 rounded-full"
          style={{
            backgroundColor: "white",
            opacity: scrollPosition <= 100 ? 1 : 0.5,
          }}
        ></div>
        <div
          className=" w-2 h-2 rounded-full"
          style={{
            backgroundColor: "white",
            opacity: scrollPosition <= 100 ? 0.5 : 1,
          }}
        ></div>
      </div>
    </div>
  );
};

export default PlayerScrollView;
