import React, { useState } from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import EyeIcon from "../../../assets/imgs/eye-white-icon.svg";

interface PlayerDetailsProps {
  name: string;
  position: string;
  posRank: string;
  ovrRank: string;
  status?: string;
  playerPos?: string;
}

const PlayerDetails: React.FC<PlayerDetailsProps> = ({
  name,
  position,
  posRank,
  ovrRank,
  status,
  playerPos
}) => {
  return (
    <div className="flex-1">
      <Paragraph
        text={name.split(" ")[0]}
        className="text-2xl md:text-3xl lg:text-4xl !font-medium leading-[100%] hidden sm:block opacity-50"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <Paragraph
        text={name.split(" ")[1]}
        className="text-4xl sm:text-5xl md:text-6xl lg:text-[77.273px] !font-bold leading-[100%] hidden sm:block mb-6"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <Paragraph
        text={name}
        className="text-4xl !font-bold !leading-[112%] sm:hidden w-[80%]"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <Paragraph
        text={position}
        ParagraphType="Pragragh22"
        className="text-base sm:text-2xl md:text-3xl lg:text-[34.091px] !font-bold leading-[100%] mt-2"
        color="textPrimary"
      />
      <Paragraph
        text={`Status:  ${status || "--"} `}
        className="text-base sm:text-[18px] lg:text-[22px] !font-semibold leading-[100%] opacity-80 mt-5 sm:block hidden"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
      <div
        className={`p-[10px] rounded-xl inline-flex gap-2 items-center mt-6 sm:hidden bg-blur w-[198px]`}
      >
        <div className="text-center flex-1">
          <Paragraph
            text={posRank || "--"}
            className="text-[20px] md:text-3xl lg:text-[34.091px] !font-bold leading-[100%]"
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <Paragraph
            text="Current Rank"
            className="text-[10px] md:text-xs lg:text-sm opacity-70 whitespace-nowrap"
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
        </div>
        <div
          className="h-[35.5px] w-[1px]"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        ></div>
        <div className="text-center flex-1">
          <Paragraph
            text={ playerPos+ ""+ ovrRank || "--"}
            className="text-[20px] md:text-3xl lg:text-[34.091px] !font-bold leading-[100%]"
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <div className="flex items-center gap-1 justify-center">
            <img className="h-[12px]" src={EyeIcon} alt={"EyeIcon"} />
            <Paragraph
              text="ROS Proj"
              className="text-[10px] md:text-xs lg:text-sm opacity-70 whitespace-nowrap"
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetails;
