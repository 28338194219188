import React, { ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  renderQBStats,
  renderRBStats,
  renderWR_TEStats,
} from "../../../lib/utils";
interface Props {
  tableHeader: string[];
  data: any[] | null;
  statsCategory: string[];
  positions: string;
}

const ResearchTable: React.FC<Props> = ({
  tableHeader,
  data,
  statsCategory,
  positions,
}) => {
  // const getColorForValue = (value: number) => {
  //   if (value >= 0 && value <= 15) {
  //     return "#F11E84"; // Red
  //   } else if (value >= 16 && value <= 36) {
  //     return "#D9AEAE"; // Orange
  //   } else if (value >= 37 && value <= 50) {
  //     return "#FBFF38"; // Yellow
  //   } else if (value >= 50) {
  //     return "#16C265"; // Green
  //   }
  // };

  const getScoreColor = (teamScore: number, oppTeamScore: number) => {
    if (teamScore > oppTeamScore) {
      return "#16C265"; // Green for win
    } else if (teamScore < oppTeamScore) {
      return "#F11E84"; // Red for loss
    } else {
      return "#FBFF38"; // Yellow for tie
    }
  };

  const renderCellContent = (weekData: any, key: string) => {
    switch (key) {
      case "WK":
        return weekData.week;
      case "OPP":
        return !weekData.opponent_team ? "-" : weekData.opponent_team;
      case "FPTS":
        return !weekData.fan_duel_fantasy_points ? "0" : weekData.fan_duel_fantasy_points;
      case "SCORE":
        return !weekData.team_points && !weekData.opponent_points
          ? "--"
          : `${weekData.team_points}-${weekData.opponent_points}`;
      case "RANK":
        return !weekData.weekly_fantasy_points_ranking ? "-" : weekData.weekly_fantasy_points_ranking;
      default:
        return renderStatsByCategoryAndKey(
          weekData,
          statsCategory,
          key,
          positions,
        );
    }
  };

  const renderStatsByCategoryAndKey = (
    weekData: any,
    category: string[],
    key: string,
    positions: string,
  ) => {
    const stats = [weekData[category[0]], weekData[category[1]]];

    if (!stats) return "-";

    switch (positions) {
      case "QB":
        return renderQBStats(stats, key);
      case "RB":
        return renderRBStats(stats, key);
      case "WR":
        return renderWR_TEStats(stats, key);
      case "TE":
        return renderWR_TEStats(stats, key);
      default:
        return "-";
    }
  };

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="overflow-x-auto">
      <div className="min-w-max pr-5">
        <Table className="w-auto !font-black">
          <TableHeader>
            <TableRow className="hover:bg-transparent border-transparent">
              {tableHeader.map((item, index) => (
                <TableHead
                  key={index}
                  className={`text-white text-center text-[10px] h-5 py-1 px-1 font-bold ${index === 0 ? "pl-4 pr-2" : index === 4 ? "pr-4" : ""
                    }`}
                >
                  {item}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody className="">
            {data.map((weekData, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={`hover:bg-transparent border-transparent text-white text-center`}
              >
                {tableHeader.map((key, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className={`text-[12px] h-5 py-1 px-1 whitespace-nowrap ${cellIndex === 0
                      ? "pl-4 pr-2"
                      : cellIndex === 4
                        ? "pr-4"
                        : ""
                      }`}
                    style={{
                      minWidth: key === "SCORE" ? "60px" : "50px",
                    }}
                  >
                    <div
                      className="px-[7px] py-[5px] rounded-sm"
                      style={{
                        backgroundColor:
                          cellIndex > 2
                            ? "#FFFFFF33"
                            : "",
                        color:
                          "white"

                      }}
                    >
                      {renderCellContent(weekData, key)}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ResearchTable;
