import { useEffect, useState } from "react";
import { Paragraph } from "../Paragraph";

const TypingParaAnimation: React.FC<{ strings: string[]; typingSpeed?: number }> = ({
  strings,
  typingSpeed = 100,
}) => {
  const [displayedStrings, setDisplayedStrings] = useState<string[]>([]);
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [animationComplete, setAnimationComplete] = useState(false); // Track if all strings have been displayed

  useEffect(() => {
    let typingInterval: NodeJS.Timeout;

    if (!animationComplete) {
      if (currentText.length < strings[currentStringIndex].length) {
        typingInterval = setTimeout(() => {
          setCurrentText((prev) => prev + strings[currentStringIndex][prev.length]);
        }, typingSpeed);
      } else {
        typingInterval = setTimeout(() => {
          if (!displayedStrings.includes(strings[currentStringIndex])) {
            setDisplayedStrings((prev) => [
              ...prev,
              strings[currentStringIndex],
            ]);
          }

          // Move to the next string
          setCurrentText('');
          setCurrentStringIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % strings.length;
            // Check if we have displayed all strings
            if (nextIndex === 0) {
              setAnimationComplete(true); // Mark as complete if we loop back to the first string
            }
            return nextIndex;
          });
        }, 10);
      }
    }

    return () => clearTimeout(typingInterval);
  }, [currentText, currentStringIndex, strings, typingSpeed, displayedStrings, animationComplete]);

  return (
    <div className="flex flex-col gap-4">
      {displayedStrings.map((text, index) => (
        <Paragraph
          text={text || ""}
          props={{ fontSize: "14px", fontWeight: 400, opacity: "0.7" }}
          ParagraphType="Paragraph22"
          color="textPrimary"
          key={index}
        />
      ))}
      {currentText && (
        <Paragraph
          text={currentText || ""}
          props={{ fontSize: "14px", fontWeight: 400, opacity: "0.7" }}
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
      )}
    </div>
  );
};

export default TypingParaAnimation;
