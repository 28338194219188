export const TYPECHAT = {
    user: "USER",
    ai_question: "AI_QUESTION",
    ai_answer: "AI_ANSWER",
};

export const TABLE_ROUTES = [
    "general_research_weekly",
    "general_research_seasonal",
    "general_research_season_recap",
];
  