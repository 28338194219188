import { MenuMobileProps } from "./type";
import { useEffect } from "react";

//style
import { CSSProperties, useState } from "react";
import { Colors } from "../../Commons/Colors/Colors";

//components
import { Paragraph } from "../../Commons/Paragraph";

//icons
import FeebackIcon from "../../../assets/imgs/feedback.svg";
import NewChatIcon from "../../../assets/imgs/new-chat.svg";
import TipsIcon from "../../../assets/imgs/tips.svg";
import CancelIcon from "../../../assets/imgs/cancel-icon.png";

//data dummies
import { dataToday, dataYesterday } from "./data";
import FeedbackModal from "../../FeedbackModal/FeedbackModal";

import { AnimatePresence, motion } from "framer-motion";
import MenuItem from "./MenuItem";

import { useSupabaseAuth } from "../../../Context/AuthContext/AuthContext";
import { fetchHistoryChatsByUser } from "../../../Controllers/ChatRotobot";
import { useSafeAreaInsets } from "../../../Context/SafeAreaContext/SafeAreaContext";
import useFirebaseAnalytics from "../../../hooks/useFirabaseAnality";
import { useNavigate } from "react-router-dom";

const MenuMobile: React.FC<MenuMobileProps> = ({
  isOpen,
  setIsOpen,
  feedbackModal,
  setFeedbackModal,
  toggleCapabilitiesModal,
}) => {
  const { top } = useSafeAreaInsets();

  const menuContainerStyle: CSSProperties = {
    position: "fixed",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    zIndex: isOpen ? "11" : "-1",
  };

  const menuOverlayStyle: CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // display: isOpen ? "block" : "none",
  };

  const closeButtonStyle: CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    background: Colors.backgroundSecundary,
  };

  const { getUserData } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [chatHistories, setChatHistories] = useState<any>([]);
  const { logEventAnalytics } = useFirebaseAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const response = await getUserData();

      if (response) {
        setCurrentUser(response?.data?.user);
      }
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const historyChats = async () => {
      const response = await fetchHistoryChatsByUser(currentUser?.id);
      if (response?.status) {
        setChatHistories(response.data?.chats);
      } else {
        setChatHistories([]);
      }
    };
    if (currentUser?.id) {
      historyChats();
    }
  }, [currentUser]);

  const handleNewChat = () => {
    logEventAnalytics("chat_menu_new", { name: "Chat Menu New Chat" });
    navigate("/chat/new");
  };

  const handleFeedBackModal = () => {
    logEventAnalytics("chat_open_feedback", {
      name: "Open Chat Menu Feedback",
    });
    setFeedbackModal(true);
  };

  const handleCapabilities = () => {
    logEventAnalytics("chat_menu_capabilities", {
      name: "Chat Menu Capabilities",
    });
    toggleCapabilitiesModal();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div
          role="button"
          style={menuOverlayStyle}
          onClick={() => setIsOpen(false)}
          tabIndex={0}
          aria-label="Close overlay"
        ></div>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="flex justify-end"
            style={menuContainerStyle}
            initial={{ x: 200 }}
            animate={{ x: 0 }}
            exit={{ x: 200 }}
            onClick={() => setIsOpen(false)}
            // initial={{
            //   opacity: 0,
            // }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <motion.div
              id="menu-wrapper"
              className="w-1/2"
              style={{
                backgroundColor: Colors.menuMobile,
                backdropFilter: "blur(100px)",
                WebkitBackdropFilter: "blur(100px)",
                paddingTop: top,
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div id="options-wrapper" className="mx-5">
                <ul className="space-y-3.5" style={{ marginTop: "30px" }}>
                  <li>
                    <div onClick={handleNewChat} className="flex items-center">
                      <div
                        className="mr-3 flex justify-center items-center h-[42px] w-[42px] rounded-full"
                        style={{
                          background: Colors.backgroundSecundary,
                        }}
                      >
                        <img src={NewChatIcon} alt="" />
                      </div>
                      <Paragraph
                        text="New Chat"
                        props={{ fontSize: "14px" }}
                        ParagraphType="PragraghSemiBold400"
                        color="textPrimary"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={handleFeedBackModal}
                    >
                      <div
                        className="mr-3 flex justify-center items-center h-[42px] w-[42px] rounded-full"
                        style={{
                          background: Colors.backgroundSecundary,
                        }}
                      >
                        <img src={FeebackIcon} alt="" />
                      </div>
                      <Paragraph
                        text="Feedback"
                        props={{ fontSize: "14px" }}
                        ParagraphType="PragraghSemiBold400"
                        color="textPrimary"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="flex items-center"
                      onClick={handleCapabilities}
                    >
                      <div
                        className="mr-3 flex justify-center items-center h-[42px] w-[42px] rounded-full"
                        style={{
                          background: Colors.backgroundSecundary,
                        }}
                      >
                        <img src={TipsIcon} alt="" />
                      </div>
                      <Paragraph
                        text="Capabilities"
                        props={{ fontSize: "14px" }}
                        ParagraphType="PragraghSemiBold400"
                        color="textPrimary"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div id="options-wrapper2" className=" overflow-y-auto h-[57%]">
                <MenuItem chatHistories={chatHistories} />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <FeedbackModal setOpen={setFeedbackModal} open={feedbackModal} />
    </>
  );
};

export default MenuMobile;
