import React, { useEffect, useState } from "react";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import ResearchTable from "../ResearchTable/ResearchTable";
import { commonHeaders, headerAccordingPlayerPosition, passingHeaders, receivingHeaders, rushingHeaders } from "../../../lib/utils";
import TableTabs from "../../Commons/TableTabs/TableTabs";

interface StatsItem {
  value: string | number;
  rank: string | null;
}

interface StatsData {
  [key: string]: StatsItem;
}

interface Props {
  statsData: StatsData | null;
  weeklyStats: any[] | null;
  playerPosition: string;
}

const ResearchStats: React.FC<Props> = ({
  statsData,
  weeklyStats,
  playerPosition,
}) => {
  const [tabsOpts] = useState(headerAccordingPlayerPosition(playerPosition));
  const [customHeaders, setCustomHeaders] = useState(commonHeaders)

  if (!statsData && !weeklyStats) {
    return (
      <Paragraph
        text="-- No Data --"
        props={{ fontSize: "22px", fontWeight: 500 }}
        className="ml-5"
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
    );
  }

  const getStatsCategoryByPosition = (position: string): string[] => {
    switch (position.toUpperCase()) {
      case "QB":
        return ["passing", "rushing"];
      case "RB":
        return ["rushing", "receiving"];
      case "WR":
        return ["receiving", "rushing"];
      case "TE":
        return ["receiving", "rushing"];
      default:
        return ["receiving", "rushing"];
    }
  };

  const statsCategory = getStatsCategoryByPosition(playerPosition);

  const getTitle = (title: string) => {
    switch (title) {
      case "totalFPS":
        return "Total FPS";
      case "avgFPPG":
        return "AVG. FPPG";
      case "receivingYards":
        return "Receiving Yards";
      case "receivingTDs":
        return "Receiving TDs";
      case "receptions":
        return "Receptions";
      case "passingYards":
        return "Passing Yards";
      case "passingTDs":
        return "Passing TDs";
      case "rushingYards":
        return "Rushing Yards";
      case "rushingTDs":
        return "Rushing TDs";
      default:
        return "";
    }
  };

  const headersMap: { [key: string]: any[] } = {
    "FANTASY": [...commonHeaders],
    "PASSING": [...commonHeaders, ...passingHeaders],
    "RUSHING": [...commonHeaders, ...rushingHeaders],
    "RECEIVING": [...commonHeaders, ...receivingHeaders]
  };

  const handleSelectPosition = (item: any) => {
    setCustomHeaders(headersMap[item.name] || [...commonHeaders, ...receivingHeaders]);
  };

  return (
    <div>
      {statsData && (
        <div className="flex items-center gap-[10px] overflow-x-auto no-scrollbar -mx-5 px-5">
          {Object.entries(statsData).map(([key, data], index) => (
            <div
              key={index}
              className="min-w-[112px] rounded-xl p-4 flex flex-col items-center justify-between"
              style={{
                backgroundColor: Colors.backgroundSecundary,
              }}
            >
              <Paragraph
                text={data.value.toString()}
                props={{
                  fontSize: "34px",
                  fontWeight: 500,
                  lineHeight: "85%",
                }}
                ParagraphType="Pragragh22"
                color="textPrimary"
              />
              <div className="text-center">
                <Paragraph
                  text={data.rank ? data.rank : ""}
                  props={{
                    fontSize: "12px",
                    lineHeight: "130%",
                    marginTop: "20px",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <Paragraph
                  text={getTitle(key)}
                  props={{
                    fontSize: "10px",
                    lineHeight: "130%",
                    color: Colors.textLigth,
                    marginTop: data.rank === "" || !data.rank ? "38px" : "",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-5 mx-5">
        <Paragraph
          text={"2024"}
          props={{
            fontSize: "12px",
            fontWeight: 600,
            marginBottom: "5px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <Paragraph
          text={"REGULAR SEASON"}
          props={{
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "100%",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
      </div>
      <div className="mt-4">
        <div className="px-5 pt-3 pb-8">
          <TableTabs
            tabItems={tabsOpts}
            tabCustomStyle="w-15.5 h-11 shrink-0"
            onTabSelect={handleSelectPosition}
            resetTabs={true}
          />
        </div>
        <div className="overflow-x-hidden pb-5">
          <div className="overflow-y-auto">
            <ResearchTable
              tableHeader={customHeaders}
              data={weeklyStats}
              statsCategory={statsCategory}
              positions={playerPosition}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchStats;
